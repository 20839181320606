import { Pipe, PipeTransform } from '@angular/core';
import { ObjectInspection } from '../../core/models/object-inspection.model';

@Pipe({
  name: 'objectsGeojson'
})
export class ObjectsGeojsonPipe implements PipeTransform {
  transform(value: ObjectInspection[]): object {
    const geoJson = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'EPSG:4326'
        }
      },
      features: []
    };

    if (!value) {
      return geoJson;
    }

    value.forEach(obj => {
      geoJson.features.push(obj.geojson);
    });

    return geoJson;
  }
}

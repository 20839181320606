<div [className]="'control-result'">
  <div [ngClass]="['item', detail | result]">
    <svg-icon *ngIf="(detail | result) === 'success'; else elseBlock" size="md" key="checkOk"></svg-icon>
    <ng-template #elseBlock>
      <svg-icon size="md" key="crossClose"></svg-icon>
    </ng-template>

    <span>{{detail | resultText}}</span>
  </div>
</div>


<table mat-table [dataSource]="dataSource" matSort [className]="'lists-table'">

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Идентификатор</th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="(element | result) === 'success'; else elseBlock">
        <svg-icon size="md" key="checkOk"></svg-icon>
        <span [className]="'check-ok'">{{element.name}}</span>
      </div>
      <ng-template #elseBlock>
        <svg-icon size="md" key="crossClose"></svg-icon>
        <span [className]="'cross-close'">{{element.name}}</span>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Тип исх.данных</th>
    <td mat-cell *matCellDef="let element">{{element.dataType}}</td>
  </ng-container>

  <ng-container matColumnDef="scale">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Масштаб</th>
    <td mat-cell *matCellDef="let element">{{element.scale ? '1:' + element.scale : ''}}</td>
  </ng-container>

  <ng-container matColumnDef="auto">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Автоисправление</th>
    <td mat-cell *matCellDef="let element">Не требуется</td>
  </ng-container>

  <ng-container matColumnDef="source">
    <th mat-header-cell *matHeaderCellDef>Источник данных</th>
    <td mat-cell *matCellDef="let element">{{element.organization}} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Статус</th>
    <td mat-cell *matCellDef="let element">
      <div [className]="'status'">
        <div [className]="'new'">Новый</div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="onSelectRow(row)"
      [style.background]="selectedRow === row ? '#C3CDD5' : ''"></tr>
</table>

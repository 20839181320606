<div [className]="'wrapper'">
  <div [className]="'header'">
    <h2>Входной контроль</h2>
    <div [className]="'controls'">
      <mat-button-toggle-group>
        <mat-button-toggle (change)="onStateChange($event.value)" value="all">Все</mat-button-toggle>
        <mat-button-toggle (change)="onStateChange($event.value)" value="checked">Прошедшие контроль</mat-button-toggle>
        <mat-button-toggle (change)="onStateChange($event.value)" value="unchecked">Непрошедшие</mat-button-toggle>
      </mat-button-toggle-group>

      <div [className]="'select'">
        <span [className]="'title'">Тип исходных данных</span>
        <mat-form-field appearance="standard">
          <select matNativeControl required>
            <option value="ctk">ЦТК ОП</option>
          </select>
        </mat-form-field>
      </div>

      <div [className]="'select'">
        <span [className]="'title'">Масштаб</span>
        <mat-form-field appearance="standard">
          <select matNativeControl required>
            <option value="50000">1:50 000</option>
          </select>
        </mat-form-field>
      </div>

      <div [className]="'select'">
        <span [className]="'title'">Дата c </span>
        <mat-form-field class="short" appearance="fill">
          <input matInput [matDatepicker]="pickerFromDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFromDate></mat-datepicker>
        </mat-form-field>
        <span> по</span>
        <mat-form-field class="short" appearance="fill">
          <input matInput [matDatepicker]="pickerToDate">
          <mat-datepicker-toggle matSuffix [for]="pickerToDate">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerToDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div [className]="'table'">
    <div [className]="'table-section item'">
      <app-lists-table
        [className]="'app-lists-table'"
        [displayedColumns]="['name', 'type', 'scale', 'source']"
        [objects]="objects"
        (onSelect)='displayDetail($event)'
      ></app-lists-table>
    </div>

    <div [className]="'detail-section item'">
      <app-control-detail [detail]="selectedObject"></app-control-detail>
    </div>
  </div>
</div>

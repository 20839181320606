import { HttpParams } from '@angular/common/http';

export const getParams = (filter): HttpParams => {
  const fromObject = {};

  if (filter) {
    Object.keys(filter).forEach(key => {
      fromObject[`filter[${key}]`] = filter[key];
    });
  }

  return new HttpParams({ fromObject });
};

<div style="width: 100%; height: 100%;" [className]="'wrapper'">
  <div [className]="'header'">
    <h2>Задания в работе</h2>
    <div [className]="'controls'">
      <mat-button-toggle-group>
        <mat-button-toggle (change)="onStateChange($event.value)" value="ALL">Любой</mat-button-toggle>
        <mat-button-toggle (change)="onStateChange($event.value)" value="NEW">Новое</mat-button-toggle>
        <mat-button-toggle (change)="onStateChange($event.value)" value="PROCESS">В работе</mat-button-toggle>
        <mat-button-toggle (change)="onStateChange($event.value)" value="VERIFY">Ожидает проверки</mat-button-toggle>
      </mat-button-toggle-group>

      <div [className]="'select'">
        <span [className]="'title'">Срок</span>
        <mat-form-field class="short" appearance="fill">
          <input matInput [matDatepicker]="pickerFromDate">
          <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
          </mat-datepicker-toggle>
          <mat-datepicker #pickerFromDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div [className]="'select'">
        <span [className]="'title'">Листы</span>
        <mat-form-field appearance="legacy">
          <mat-label>Листы</mat-label>
          <input matInput placeholder="Листы">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>

      <div [className]="'select'">
        <span [className]="'title'">Исполнитель</span>
        <mat-form-field appearance="legacy">
          <mat-label>Исполнитель</mat-label>
          <input matInput placeholder="Исполнитель">
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div [className]="'table'">
    <div [className]="'item'">
      <app-tasks-table
        [className]="'app-tasks-table'"
        [displayedColumns]="['id', 'deadline', 'state', 'lists', 'executor']"
        [tasks]="tasks"
        (onSelect)='displayDetail($event)'
      ></app-tasks-table>
    </div>

  </div>
</div>

import { Component, Input } from '@angular/core';
import { ObjectValidation } from '../core/models/object-validation.model';

@Component({
  selector: 'app-validations-list',
  templateUrl: './validations-list.component.html',
  styleUrls: ['./validations-list.component.scss']
})
export class ValidationsListComponent {
  @Input() validations: ObjectValidation[];
}

import { Pipe, PipeTransform } from '@angular/core';
import { TaskList } from '../../core/models/task-list.model';

@Pipe({
  name: 'tasksListsText'
})
export class TasksListsTextPipe implements PipeTransform {
  transform(value?: TaskList[]): string {
    if (!value) {
      return '';
    }

    return value.map(l => l.name).join(', ');
  }
}

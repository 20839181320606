import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateTaskDialogDataItem } from '../../interfaces/CreateTaskDialogData';

@Component({
  selector: 'app-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss']
})
export class CreateTaskDialogComponent implements OnInit {
  names: string;
  date: Date = new Date();

  constructor(public dialogRef: MatDialogRef<CreateTaskDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CreateTaskDialogDataItem[]) {
    this.names = data.map(item => item.name).join(', ');
  }

  ngOnInit(): void {
  }

  onCreate(): void {
    this.dialogRef.close();
  }
}

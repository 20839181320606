<style>
</style>

<mat-grid-list cols="20" rowHeight="100%">
  <mat-grid-tile [colspan]="3" [rowspan]="1" [style.background]="'#FAFAFA'">
    <div [className]="'title'">
      <span [className]="'sub-title'">Росреестр</span>
      <span [className]="'sub-title-eeko'">ЕЭКО</span>
    </div>
    <div [className]="'menu'">
      <a
        [routerLink]="['/input']"
        [className]="'item'"
        [routerLinkActive]="['is-active']">
        Входной контроль
      </a>
      <a
        [routerLink]="['/format-logical']"
        [className]="'item'"
        [routerLinkActive]="['is-active']">
        Форматно-логический контроль
      </a>
      <a
        [routerLink]="['/tasks']"
        [className]="'item'"
        [routerLinkActive]="['is-active']">
        Задания
      </a>
      <a
        [routerLink]="['/applications']"
        [className]="'item'"
        [routerLinkActive]="['is-active']">
        Подготовка по заявлениям
      </a>
    </div>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="17" [rowspan]="1">
    <router-outlet></router-outlet>
  </mat-grid-tile>
</mat-grid-list>

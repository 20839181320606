import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Adapter } from './adapter';
import { Refs } from '../models/ref.model';
import { map } from 'rxjs/operators';
import { JsonApiResponse } from '../models/json-api-response';

@Injectable({
  providedIn: 'root',
})
export class RefsAdapter implements Adapter<Refs> {
  adapt(item: any): Refs {
    console.log(item);
    return {
      dataType: item.dt,
      organization: item.organization,
      scale: item.scale
    } as Refs;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RefsService {
  refsChange: Subject<Refs> = new Subject<Refs>();
  refs: Refs = null;

  constructor(private http: HttpClient, private adapter: RefsAdapter) {
  }

  all(): Observable<Refs> {
    const url = `/api/refs`;

    return this.http.get(url).pipe(
      map((dataResponse: JsonApiResponse) => {
        const refs = this.adapter.adapt(dataResponse.data);
        this.refsChange.next(refs);
        this.refs = refs;
        return refs;
      })
    );
  }

  getRefs(): Observable<Refs> {
    return this.refsChange.asObservable();
  }
}

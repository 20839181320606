import { Component } from '@angular/core';
import { ObjectsFilter } from '../core/models/objects-filter';
import { TasksService } from '../core/services/tasks.service';
import { Task } from '../core/models/task.model';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent {
  public tasks: Task[];
  selectedTask = null;

  public constructor(private tasksService: TasksService) {
    const filter: ObjectsFilter = {};

    tasksService
    .all(filter)
    .subscribe(tasks => (this.tasks = tasks));
  }

  // tslint:disable-next-line:typedef
  onStateChange(state) {
  }

  // tslint:disable-next-line:typedef
  displayDetail(task: Task) {
    this.selectedTask = task;
  }
}

export default {
  // N - ‘NEW’
  // R - ‘READY’- Задание готово к выполнению
  // P - ‘PROCESS’ - Задание в процессе выполнения
  // D - ‘DONE’ - Задание выполнено
  // V - ‘VERIFY’ - Задание на проверке
  // C - ‘COMPLETE’ - Задание завершено

  E: {
    className: 'new',
    msg: 'Новое'
  },
  N: {
    className: 'new',
    msg: 'Новое'
  },
  R: {
    className: 'new',
    msg: 'Новое'
  },
  P: {
    className: 'work',
    msg: 'В работе'
  },
  D: {
    className: 'done',
    msg: 'Готово'
  },
  V: {
    className: 'wait',
    msg: 'Ожидает проверки'
  },
  C: {
    className: 'done',
    msg: 'Готово'
  },
  F: {
    className: 'failed',
    msg: 'Готово'
  }
};

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { getFullUrl } from '../../utils/url';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('intercepted');
    return next.handle(req).pipe(
      tap(evt => {}),
      catchError(err => this.handleError(err))
    );
  }


  private handleError(err: HttpErrorResponse): Observable<any> {
    console.log('caught');
    if (err.status === 401 || err.status === 403) {
      const nextUrl = getFullUrl();
      const url = `/accounts/keycloak/login/?process=login&next=${nextUrl}`;
      window.location.href = url;
      return of(err.message);
    }
    return throwError(err);
  }
}

<div [className]="'object-detail'">
  <div *ngIf="detail; else elseBlock" [className]="'content'">
    <div [className]="'header'">
      <h3>{{detail.name}}</h3>
      <span [className]="'item'">{{detail.dataType}}</span>
      <span [className]="'item'">{{detail | scale}}</span>
    </div>
    <div>
      <app-control-result [detail]="detail"></app-control-result>
    </div>
    <div [className]="'controls-list'">
      <span>Контроли</span>
      <div [className]="'validations'">
        <app-validations-list [validations]="detail.validations"></app-validations-list>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <div [className]="'content'">Выберите объект контроля для отображения сведений</div>
  </ng-template>
</div>

import {
  Component, EventEmitter,
  Input, OnChanges,
  Output, SimpleChanges, ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { Task } from '../core/models/task.model';

@Component({
  selector: 'app-tasks-table',
  templateUrl: './tasks-table.component.html',
  styleUrls: ['./tasks-table.component.scss']
})
export class TasksTableComponent implements OnChanges {
  dataSource: MatTableDataSource<Task> = new MatTableDataSource([]);
  selectedRow = null;

  @Input() tasks: Task[];
  @Input() displayedColumns: string[];

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    if ('tasks' in changes &&
      changes.tasks.currentValue !== changes.tasks.previousValue) {
      this.dataSource = new MatTableDataSource(changes.tasks.currentValue);

      setTimeout(() => {
        this.dataSource.sort = this.sort;
      });
    }
  }

  // tslint:disable-next-line:typedef
  onSelectRow(row) {
    this.selectedRow = row;
    this.onSelect.emit(row);
  }
}

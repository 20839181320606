import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScalePipe } from './pipes/scale.pipe';
import { ResultPipe } from './pipes/result.pipe';
import { ResultTextPipe } from './pipes/result-text.pipe';
import { TasksListsTextPipe } from './pipes/tasks-lists-text.pipe';
import { TasksStatusTextPipe } from './pipes/tasks-status-text.pipe';
import { TasksStatusClassNamePipe } from './pipes/tasks-status-class-name.pipe';

import * as fromComponents from './components';
import { ObjectsGeojsonPipe } from './pipes/objects-geojson.pipe';
import { ObjectsWktFeaturesPipe } from './pipes/objects-wkt-features.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ScalePipe,
    ResultPipe,
    ResultTextPipe,
    TasksListsTextPipe,
    TasksStatusTextPipe,
    TasksStatusClassNamePipe,
    ...fromComponents.components,
    ObjectsGeojsonPipe,
    ObjectsWktFeaturesPipe
  ],
  exports: [
    ScalePipe,
    ResultPipe,
    ResultTextPipe,
    TasksListsTextPipe,
    TasksStatusClassNamePipe,
    TasksStatusTextPipe,
    ...fromComponents.components,
    ObjectsGeojsonPipe,
    ObjectsWktFeaturesPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
  ]
})
export class SharedModule {
}

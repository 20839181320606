import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InputControlComponent } from './input-control/input-control.component';
import { FormatLogicalControlComponent } from './format-logical-control/format-logical-control.component';
import { TasksComponent } from './tasks/tasks.component';
import { ApplicationsComponent } from './applications/applications.component';

const routes: Routes = [
  { path: 'input', component: InputControlComponent },
  { path: 'format-logical', component: FormatLogicalControlComponent },
  { path: 'tasks', component: TasksComponent },
  { path: 'applications', component: ApplicationsComponent },
  { path: '', redirectTo: '/input', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

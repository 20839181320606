import { Pipe, PipeTransform } from '@angular/core';
import TASK_STATUSES from '../constants/task-status';
import { Task } from '../../core/models/task.model';

@Pipe({
  name: 'tasksStatusClassName'
})
export class TasksStatusClassNamePipe implements PipeTransform {

  transform(value: Task): string {
    if (!value.state) {
      return '';
    }

    const { state } = value;

    if (state in TASK_STATUSES) {
      return TASK_STATUSES[state].className;
    }

    throw new Error(`Unknown state: ${state}`);
  }
}

<div [className]="'wrapper'">
  <div [className]="'header'">
    <div [className]="'sub-header'">
      <h2>Форматно-логический контроль</h2>
      <mat-button-toggle-group>
        <mat-button-toggle (change)="onViewModeChange($event.value)" value="list">Списком</mat-button-toggle>
        <mat-button-toggle (change)="onViewModeChange($event.value)" value="map">На карте</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div [className]="'controls'" *ngIf="refs">
      <mat-button-toggle-group>
        <mat-button-toggle (change)="onFilterChange('state', $event.value)" value="CO,CF">Все</mat-button-toggle>
        <mat-button-toggle (change)="onFilterChange('state', $event.value)" value="CO">Прошедшие контроль
        </mat-button-toggle>
        <mat-button-toggle (change)="onFilterChange('state', $event.value)" value="CF">Непрошедшие</mat-button-toggle>
      </mat-button-toggle-group>

      <div [className]="'select'">
        <span [className]="'title'">Тип исходных данных</span>
        <mat-form-field appearance="standard">
          <select (change)="onFilterChange('dataType', null)"
                  [(ngModel)]="filterDataType"
                  matNativeControl
                  required>
            <option value="">Любой</option>
            <option *ngFor="let dt of refs.dataType" value="{{dt.dt}}">
              {{dt.title}}
            </option>
          </select>
        </mat-form-field>
      </div>

      <div [className]="'select'">
        <span [className]="'title'">Масштаб</span>
        <mat-form-field appearance="standard">
          <select (change)="onFilterChange('scale', null)"
                  [(ngModel)]="filterScale"
                  matNativeControl required>
            <option value="">Любой</option>
            <option *ngFor="let scale of refs.scale" value="{{scale.scale}}">
              {{scale.title}}
            </option>
          </select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div *ngIf="viewModeList; else elseBlock" [className]="'table'">
    <div [className]="'table-section item'">
      <app-lists-table
        [className]="'app-lists-table'"
        [displayedColumns]="['name', 'type', 'scale', 'auto', 'source']"
        [objects]="objects"
        (onSelect)='displayDetail($event)'
      ></app-lists-table>
    </div>
    <div [className]="'detail-section item'">
      <app-control-detail [detail]="selectedObject"></app-control-detail>
    </div>
  </div>
  <ng-template #elseBlock>
    <div [className]="'map'">
      <app-objects-map
        [objects]="objects | objectsWktFeatures"
        [className]="'item'">
      </app-objects-map>
    </div>
  </ng-template>


</div>


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Adapter } from './adapter';

import { ObjectInspection } from '../models/object-inspection.model';

import { JsonApiResponse } from '../models/json-api-response';
import { ObjectsFilter } from '../models/objects-filter';

import { getParams } from '../../utils/http';

@Injectable({
  providedIn: 'root',
})
export class CourseAdapter implements Adapter<ObjectInspection> {
  adapt(item: any): ObjectInspection {
    return {
      id: item.id,
      name: item.name,
      dataType: item.dt,
      state: item.state,
      scale: item.scale,
      sheet: item.sheet,
      organization: item.organization,
      gkname: item.gkname,
      geom: item.geom,
      validations: item.validations
    } as ObjectInspection;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ObjectsService {
  constructor(private http: HttpClient, private adapter: CourseAdapter) {
  }

  all(filter?: ObjectsFilter): Observable<ObjectInspection[]> {
    const url = `/api/objects`;
    const params = getParams(filter);

    return this.http.get(url, { params }).pipe(
      map((dataResponse: JsonApiResponse) => {
        return dataResponse.data.map((item) => this.adapter.adapt(item));
      })
    );
  }
}

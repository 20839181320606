import { Component, Input, OnInit } from '@angular/core';
import { ObjectInspection } from '../core/models/object-inspection.model';

@Component({
  selector: 'app-control-detail',
  templateUrl: './control-detail.component.html',
  styleUrls: ['./control-detail.component.scss']
})
export class ControlDetailComponent {
  @Input() detail?: ObjectInspection;
}

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatGridListModule } from '@angular/material/grid-list';
import { InputControlComponent } from './input-control/input-control.component';
import { FormatLogicalControlComponent } from './format-logical-control/format-logical-control.component';
import { TasksComponent } from './tasks/tasks.component';
import { ApplicationsComponent } from './applications/applications.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { ListsTableComponent } from './lists-table/lists-table.component';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from './core/core.module';
import { MatSortModule } from '@angular/material/sort';
import icons from '../assets/svg/svg-icons';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { ControlDetailComponent } from './control-detail/control-detail.component';
import { SharedModule } from './shared/shared.module';
import { ControlResultComponent } from './control-result/control-result.component';
import { ValidationsListComponent } from './validations-list/validations-list.component';
import { TasksTableComponent } from './tasks-table/tasks-table.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    InputControlComponent,
    FormatLogicalControlComponent,
    TasksComponent,
    ApplicationsComponent,
    ListsTableComponent,
    ControlDetailComponent,
    ControlResultComponent,
    ValidationsListComponent,
    TasksTableComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatMomentDateModule,
    MatTableModule,
    CoreModule,
    MatSortModule,
    SvgIconsModule.forRoot({
      sizes: {
        xs: '10px',
        sm: '12px',
        md: '18px',
        lg: '20px'
      },
      icons
    }),
    SharedModule,
    FormsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

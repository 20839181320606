import { Component, Input, OnInit } from '@angular/core';
import { ObjectInspection } from '../core/models/object-inspection.model';

@Component({
  selector: 'app-control-result',
  templateUrl: './control-result.component.html',
  styleUrls: ['./control-result.component.scss']
})
export class ControlResultComponent {
  @Input() detail?: ObjectInspection;
}

import { Component } from '@angular/core';

import { ObjectsService } from '../core/services/objects.service';
import { ObjectInspection } from '../core/models/object-inspection.model';
import { ObjectsFilter } from '../core/models/objects-filter';
import { RefsService } from '../core/services/refs.service';
import { Refs } from '../core/models/ref.model';

@Component({
  selector: 'app-format-logical-control',
  templateUrl: './format-logical-control.component.html',
  styleUrls: ['./format-logical-control.component.scss']
})
export class FormatLogicalControlComponent {
  public objects: ObjectInspection[];
  selectedObject = null;
  viewModeList = true;
  refs: Refs = null;

  objectsService: ObjectsService;

  filter: ObjectsFilter;
  filterDataType = '';
  filterScale = '';

  public constructor(private objectsServiceInj: ObjectsService, private refsService: RefsService) {
    this.filter = {
      state: 'CO,CF'
    };

    this.objectsService = objectsServiceInj;
    this.fillObjects();

    this.refs = refsService.refs;
    refsService.getRefs().subscribe((refs) => {
      this.refs = refs;
    });
  }

  // tslint:disable-next-line:typedef
  fillObjects() {
    this.objectsService
    .all(this.filter)
    .subscribe(objects => (this.objects = objects));
  }

  // tslint:disable-next-line:typedef
  onFilterChange(typeFilter, value) {
    if (typeFilter === 'state') {
      this.filter.state = value;
    }

    if (typeFilter === 'scale') {
      if (this.filterScale === '') {
        delete this.filter.scale;
      } else {
        this.filter.scale = parseInt(this.filterScale, 10);
      }
    }

    if (typeFilter === 'dataType') {
      if (this.filterDataType === '') {
        delete this.filter.source;
      } else {
        this.filter.source = this.filterDataType;
      }
    }

    this.fillObjects();
  }

  // tslint:disable-next-line:typedef
  displayDetail(objectInspection: ObjectInspection) {
    this.selectedObject = { ...objectInspection };
  }

  // tslint:disable-next-line:typedef
  onViewModeChange(viewMode) {
    this.viewModeList = viewMode === 'list';
  }
}

import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Adapter } from './adapter';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ObjectsFilter } from '../models/objects-filter';

import { Task } from '../models/task.model';

import { JsonApiResponse } from '../models/json-api-response';

import { getParams } from '../../utils/http';
import { split } from 'ts-node';

@Injectable({
  providedIn: 'root',
})
export class TaskAdapter implements Adapter<Task> {
  adapt(item: any): Task {
    return {
      id: item.id,
      dataFilename: item.data_filename,
      state: item.state,
      executor: item.executor,
      deadline: item.deadline ? new Date(Date.parse(item.deadline)) : undefined,
      lists: item.objs
    } as Task;
  }
}

const handleError = (error: HttpErrorResponse) => {
  if (error.error instanceof ErrorEvent) {
    console.error('An error occurred:', error.error.message);
  } else {
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
  }

  return throwError(
    'Something bad happened; please try again later.');
};

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(private http: HttpClient, private adapter: TaskAdapter) {
  }

  all(filter?: ObjectsFilter): Observable<Task[]> {
    const url = `/api/tasks`;
    const params = getParams(filter);

    return this.http.get(url, { params }).pipe(
      map((dataResponse: JsonApiResponse) => {
        return dataResponse.data.map((item) => this.adapter.adapt(item));
      })
    );
  }

  create(operatorGuid: string, deadline: string, objectIds: number[]): Observable<{}> {
    const url = `/api/tasks`;

    const data = {
      operator_guid: operatorGuid,
      deadline: deadline.split('T')[0],
      object_ids: objectIds
    };

    return this.http.post(url, { data }).pipe(catchError(handleError)
    );
  }
}

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { ObjectInspection } from '../../../core/models/object-inspection.model';

import Map from 'ol/Map';
import View from 'ol/View';
import { Tile, Vector } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import Select from 'ol/interaction/Select';
import * as olProj from 'ol/proj';
import { getFailStyle, getSelectedStyle, getSuccessStyle, getUndefinedStyle } from '../../../utils/ol-styles';
import { MatDialog } from '@angular/material/dialog';
import { CreateTaskDialogComponent } from '../create-task-dialog/create-task-dialog.component';
import { CreateTaskDialogDataItem, CreateTaskDialogDataResult } from '../../interfaces/CreateTaskDialogData';
import { TasksService } from '../../../core/services/tasks.service';

@Component({
  selector: 'app-objects-map',
  templateUrl: './objects-map.component.html',
  styleUrls: ['./objects-map.component.scss']
})
export class ObjectsMapComponent implements AfterViewInit, OnChanges {
  @Input() objects: ObjectInspection[];

  @ViewChild('mapContainer', { static: false }) mapContainer: ElementRef;

  objectsSource = null;
  map = null;
  selectInteraction = null;
  countSelected = 0;

  constructor(public dialog: MatDialog, private tasksService: TasksService) {
  }

  openDialog(): void {
    const data: CreateTaskDialogDataItem[] = this.selectInteraction.getFeatures().getArray().map(f => {
      const props = f.getProperties();
      return { name: props.name, id: props.id } as CreateTaskDialogDataItem;
    });

    const dialogRef = this.dialog.open(CreateTaskDialogComponent, {
      width: '500px',
      data
    });

    dialogRef.afterClosed().subscribe((result: CreateTaskDialogDataResult) => {
      this.tasksService.create(
        '00000000-0000-0000-0000-000000000000',
        result.date.toISOString(),
        result.data.map(item => item.id)
      ).subscribe(() => {
        console.log('Task was created');
      });
    });
  }

  ngAfterViewInit(): void {
    this.objectsSource = new VectorSource({
      features: this.objects
    });

    const makeStyle = (feature, resolution) => {
      const props = feature.getProperties();
      if (props.state === 'CO') {
        return getSuccessStyle();
      } else if (props.state === 'CF') {
        return getFailStyle();
      } else {
        return getUndefinedStyle();
      }
    };

    const objectsLayer = new Vector({
      source: this.objectsSource,
      style: makeStyle
    });

    this.map = new Map({
      target: 'mapContainer',
      layers: [
        new Tile({
          source: new OSM()
        }),
        objectsLayer
      ],
      view: new View({
        center: olProj.fromLonLat([37.41, 8.82]),
        zoom: 4
      })
    });

    const selectInteraction = new Select({
      multi: false,
      style: () => getSelectedStyle()
    });

    selectInteraction.on('select', (e) => {
      this.countSelected = e.target.getFeatures().getLength();
      console.log(e.target.getFeatures().getLength());
    });
    this.selectInteraction = selectInteraction;
    this.map.addInteraction(selectInteraction);

    setTimeout(() => {
      this.map.updateSize();
      this.map.getView().fit(this.objectsSource.getExtent());
    }, 200);
  }

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    if ('objects' in changes &&
      changes.objects.currentValue !== changes.objects.previousValue) {
      if (!this.objectsSource) {
        return;
      }
      this.objectsSource.clear();
      this.objectsSource.addFeatures(changes.objects.currentValue);
      this.map.getView().fit(this.objectsSource.getExtent());
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import OBJECT_STATES from '../constants/objects-states';
import { ObjectInspection } from '../../core/models/object-inspection.model';

@Pipe({
  name: 'resultText'
})
export class ResultTextPipe implements PipeTransform {

  transform(value: ObjectInspection): string {
    const { state } = value;

    if (state in OBJECT_STATES) {
      if (OBJECT_STATES[state].success === true) {
        return OBJECT_STATES[state].msg;
      }
      if (OBJECT_STATES[state].success === false) {
        return OBJECT_STATES[state].msg;
      }
    }

    throw new Error(`Unknown state: ${state}`);
  }

}

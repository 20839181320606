<ul [className]="'validations-list'">
  <li *ngFor="let validation of validations">
    <svg-icon *ngIf="validation.result; else elseBlock"
              size="md" key="checkOk">
    </svg-icon>
    <ng-template #elseBlock>
      <svg-icon size="md" key="crossClose"></svg-icon>
    </ng-template>

    <span [className]="'message'" [title]="validation.msg">
      {{validation.name}}
    </span>
  </li>
</ul>

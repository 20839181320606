import { Fill, Stroke, Style } from 'ol/style';
import { DEVICE_PIXEL_RATIO } from 'ol/has';

export const getUndefinedStyle = () => {
  const fill = new Fill({
    color: 'rgba(208,208,208,0.3)'
  });

  const stroke = new Stroke({
    color: '#9e9e9e',
    width: 2
  });

  return new Style({
    fill,
    stroke
  });
};

export const getSuccessStyle = () => {
  const fill = new Fill({
    color: 'rgba(52, 181, 209, 0.3)'
  });

  const stroke = new Stroke({
    color: '#34B5D1',
    width: 2
  });

  return new Style({
    fill,
    stroke
  });
};

export const getFailStyle = () => {
  const fill = new Fill({
    color: 'rgba(220, 95, 68, 0.3)'
  });

  const stroke = new Stroke({
    color: '#DC5F44',
    width: 2
  });

  return new Style({
    fill,
    stroke
  });
};

export const getSelectedStyle = () => {
  const fill = new Fill({
    color: 'rgba(220, 95, 68, 0.3)'
  });

  const stroke = new Stroke({
    color: '#DC5F44',
    width: 2
  });

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const pixelRatio = DEVICE_PIXEL_RATIO;

  const pattern = (() => {
    canvas.width = 8 * pixelRatio;
    canvas.height = 8 * pixelRatio;

    const x0 = canvas.width + 4;
    const x1 = -4;
    const y0 = -2;
    const y1 = canvas.height + 2;
    const offset = 32;

    context.strokeStyle = '#34B5D1';
    context.lineWidth = 2;
    context.beginPath();
    context.moveTo(x0, y0);
    context.lineTo(x1, y1);
    context.moveTo(x0 - offset, y0);
    context.lineTo(x1 - offset, y1);
    context.moveTo(x0 + offset, y0);
    context.lineTo(x1 + offset, y1);
    context.stroke();

    return context.createPattern(canvas, 'repeat');
  })();

  fill.setColor(pattern);

  return new Style({
    fill,
    stroke
  });
};

import { Pipe, PipeTransform } from '@angular/core';
import { ObjectInspection } from '../../core/models/object-inspection.model';

import { WKT } from 'ol/format';

const format = new WKT();

@Pipe({
  name: 'objectsWktFeatures'
})
export class ObjectsWktFeaturesPipe implements PipeTransform {
  transform(value: ObjectInspection[]): object {
    const features = [];

    if (!value) {
      return features;
    }

    return value.map(obj => {
      const feature = format.readFeature(obj.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      feature.setProperties(obj);
      return feature;
    });
  }
}

import { Component } from '@angular/core';
import { RefsService } from './core/services/refs.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'eeko-import';

  constructor(private refsService: RefsService) {
    refsService.all().subscribe();
  }
}

import { Component } from '@angular/core';

import { ObjectInspection } from '../core/models/object-inspection.model';
import { ObjectsService } from '../core/services/objects.service';
import { ObjectsFilter } from '../core/models/objects-filter';

@Component({
  selector: 'app-input-control',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.scss']
})
export class InputControlComponent {
  public objects: ObjectInspection[];
  selectedObject = null;

  public constructor(private objectsService: ObjectsService) {
    const filter: ObjectsFilter = {
      state: 'IO,IF'
    };

    objectsService
    .all(filter)
    .subscribe(objects => (this.objects = objects));
  }

  // tslint:disable-next-line:typedef
  onStateChange(state) {
  }

  // tslint:disable-next-line:typedef
  displayDetail(objectInspection: ObjectInspection) {
    this.selectedObject = { ...objectInspection };
  }
}

<table mat-table [dataSource]="dataSource" matSort [className]="'tasks-table'">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
    <td mat-cell *matCellDef="let element">{{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="deadline">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Срок</th>
    <td mat-cell *matCellDef="let element">
      {{
      element.deadline.toLocaleDateString("ru-RU",
        {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Статус</th>
    <td mat-cell *matCellDef="let element">
      <div [ngClass]="[(element | tasksStatusClassName), 'status']">
        {{element | tasksStatusText}}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="lists">
    <th mat-header-cell *matHeaderCellDef>Листы</th>
    <td mat-cell *matCellDef="let element">{{element.lists | tasksListsText}}</td>
  </ng-container>

  <ng-container matColumnDef="executor">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Исполнитель</th>
    <td mat-cell *matCellDef="let element">{{element.executor}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      (click)="onSelectRow(row)"
      [style.background]="selectedRow === row ? '#C3CDD5' : ''"></tr>
</table>

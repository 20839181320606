import { Pipe, PipeTransform } from '@angular/core';
import { ObjectInspection } from '../../core/models/object-inspection.model';

import OBJECT_STATES from '../constants/objects-states';
import RESULT_PIPE from '../constants/result-pipe';

@Pipe({
  name: 'result'
})
export class ResultPipe implements PipeTransform {
  transform(value?: ObjectInspection): string {
    const { state } = value;

    if (state in OBJECT_STATES) {
      if (OBJECT_STATES[state].success === true) {
        return RESULT_PIPE.SUCCESS;
      }
      if (OBJECT_STATES[state].success === false) {
        return RESULT_PIPE.FAIL;
      }
    }

    throw new Error(`Unknown state: ${state}`);
  }
}

export default {
  IO: {
    success: true,
    msg: 'Входной контроль пройден'
  },
  IF: {
    success: false,
    msg: 'Входной контроль не пройден'
  },
  CO: {
    success: true,
    msg: 'ФЛК пройден'
  },
  CF: {
    success: false,
    msg: 'ФЛК не пройден'
  }
};

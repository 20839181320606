import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '../../core/models/task.model';

import TASK_STATUSES from '../constants/task-status';

@Pipe({
  name: 'tasksStatusText'
})
export class TasksStatusTextPipe implements PipeTransform {

  transform(value?: Task): unknown {
    if (!value || !value.state) {
      return '';
    }

    const { state } = value;

    if (state in TASK_STATUSES) {
      return TASK_STATUSES[state].msg;
    }

    throw new Error(`Unknown state: ${state}`);
  }
}

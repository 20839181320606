import { Pipe, PipeTransform } from '@angular/core';
import { ObjectInspection } from '../../core/models/object-inspection.model';

@Pipe({
  name: 'scale'
})
export class ScalePipe implements PipeTransform {
  transform(value: ObjectInspection): string {
    if (!value.scale) {
      return '';
    }
    return `1 : ${value.scale}`;
  }
}

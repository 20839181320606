<h1 mat-dialog-title>Новая задача</h1>
<div [className]="'names'">
  {{names}}
</div>
<div [className]="'operations'">
  <span>Приведение к единому классификатору</span>
  <span>Приведение к единой системе координат</span>
  <span>Подготовка пространственных данных к открытому опубликованию</span>
  <span>Сводка объектов соседних номенклатурных листов</span>
  <span>Исправление ошибок ФЛК</span>
</div>

<div [className]="'controls'">
  <div [className]="'left'">
    <mat-form-field class="short" appearance="fill">
      <input matInput [(ngModel)]="date" [matDatepicker]="pickerFromDate">
      <mat-datepicker-toggle matSuffix [for]="pickerFromDate">
      </mat-datepicker-toggle>
      <mat-datepicker #pickerFromDate></mat-datepicker>
    </mat-form-field>
  </div>
  <div [className]="'right'">
    <div mat-dialog-actions [className]="'actions'">
      <button mat-flat-button color="primary" [mat-dialog-close]="{data: data, date: date}">Назначить</button>
    </div>
  </div>
</div>

import {
  Component, EventEmitter,
  Input, OnChanges, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ObjectInspection } from '../core/models/object-inspection.model';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-lists-table',
  templateUrl: './lists-table.component.html',
  styleUrls: ['./lists-table.component.scss']
})
export class ListsTableComponent implements OnChanges {
  dataSource: MatTableDataSource<ObjectInspection> = new MatTableDataSource([]);
  selectedRow = null;

  @Input() objects: ObjectInspection[];
  @Input() displayedColumns: string[];

  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  // tslint:disable-next-line:typedef
  ngOnChanges(changes: SimpleChanges) {
    if ('objects' in changes &&
      changes.objects.currentValue !== changes.objects.previousValue) {
      this.dataSource = new MatTableDataSource(changes.objects.currentValue);
      setTimeout(() => {
        this.dataSource.sort = this.sort;
      });
    }
  }

  // tslint:disable-next-line:typedef
  onSelectRow(row) {
    this.selectedRow = row;
    this.onSelect.emit(row);
  }
}
